.navbar {
  width: auto;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;
}

.navbar-left {
  display: flex;
  align-items: center;
  margin-left: 10px;
  pointer-events: auto;
}

.navbar-right {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-right: 40px;
  z-index: 10000000;
  pointer-events: auto;
}

.toggle-animations {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 4px 8px;
  font-size: 0.7rem;
  z-index: 9999;
  background: transparent;
  border: 1px solid #00ff00;
  color: #00ff00;
  cursor: pointer;
  font-family: inherit;
  transition: all 0.3s ease;
  pointer-events: auto;
}

.toggle-animations:hover {
  background: #00ff00;
  color: black;
}

@media (max-width: 1400px) {
  .navbar {
    display: none;
  }
}

@media (max-width: 1400px) {
  .action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .toggle-animations {
    display: block;
    margin-top: 10px;
    width: auto;
  }
}
@media (max-width: 768px) {
  .action-button,
  .toggle-animations {
    width: 80%; /* Make buttons smaller */
    padding: 8px 16px; /* Reduce padding */
    font-size: 14px; /* Adjust font size */
  }
  .toggle-animations {
    position: static;
    margin-top: 10px; /* Place below social links on mobile */
  }
}
