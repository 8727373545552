/* Container for the Projects section */
.projects-container {
  padding: 60px 20px;
  /* Adjust padding as needed */
  background-color: rgba(0, 0, 0, 0.95);
  color: #00ff00;
  box-sizing: border-box;
}

h2 {
  font-size: 2rem;
  margin-top: 5px;
  color: #00ff00;
  font-family: "Terminus", monospace;
}

/* Projects title */
.projects-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
  color: #ffffff;
  font-family: "Terminus", monospace;
}

.projects-section-title {
  text-align: center;
  color: #00ff00;
  font-size: 2.5rem;
  margin: 2rem 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.projectGrid {
  display: grid;
  grid-template-columns: 1fr;
  /* Single column */
  gap: 20px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
  margin-top: 20px;
}

/* Optional: Remove or Adjust Media Queries for Multi-column Layout */
@media (min-width: 1400px) {
  .projectGrid {
    grid-template-columns: 1fr;
    /* Keep single column even on larger screens */
  }

  .terminalBox {
    height: auto;
  }
}

/* Optional: Remove the min-width constraint if needed */
@media (min-width: 1200px) {
  .projectContent {
    flex-direction: row;
    align-items: flex-start;
  }

  .projectImg {
    width: 100px;
    height: 100px;
  }
}

.langLogo {
  position: absolute;
  top: 8px;
  /* Adjust as necessary for positioning */
  right: 10px;
  width: 25px;
  /* Adjust size */
  height: 25px;
  object-fit: contain;
  z-index: 2;
}

.terminalBox {
  position: relative;
  background-color: rgba(0, 0, 0, 0.925);
  border: 1px solid #00ff00;
  border-radius: 5px;
  overflow: hidden;
  font-family: "Courier New", monospace;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.terminalHeader {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00ff00;
  position: relative;
}

.terminalTitle {
  font-size: 14px;
}

.terminalControls {
  display: flex;
  gap: 5px;
}

.terminalControl {
  width: 12px;
  height: 12px;
  border: 1px solid #00ff00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: not-allowed;
}

.terminalContent {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.terminalPrompt {
  margin: 0 0 10px 0;
  font-weight: bold;
}

.projectContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.projectImg {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: cover;
  align-self: center;
}

.projectText {
  flex: 1;
}

.projectText p {
  margin: 0;
}

.secondTitle {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #fff;
}

.seemore {
  color: #ffffff;
  text-decoration: none;
}

.seemore:hover {
  text-decoration: underline;
}

/* Responsive Adjustments */
@media (min-width: 1200px) {
  .projectContent {
    flex-direction: row;
    align-items: flex-start;
  }

  .projectImg {
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 1400px) {
  .projectGrid {
    grid-template-columns: 1fr;
    /* Override to single column */
  }

  .terminalBox {
    height: auto;
  }
}

/* Additional Responsive Styles */

@media (max-width: 768px) {
  .projectContent {
    flex-direction: column;
  }

  .projectImg {
    max-width: 100%;
  }

  /* Specific adjustments for Boiler Blockchain image */
  .project-card[data-id="block"] img {
    margin: 0 auto;
    padding: 8px;
    height: 130px;
    /* Reduced height specifically for this image */
    width: 80%;
  }

  /* General card improvements for mobile */
  .project-card {
    padding-bottom: 15px;
  }

  /* Improve spacing and alignment for text elements */
  .project-info h3 {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .project-info p {
    text-align: left;
    margin-bottom: 15px;
    line-height: 1.4;
  }

  /* Improve button positioning */
  .see-more {
    align-self: flex-start;
    margin-top: 10px;
    display: inline-block;
  }

  /* Adjust project status indicators */
  .project-status {
    top: 5px;
    right: 5px;
  }

  .status-dot {
    padding: 3px 8px;
    font-size: 0.75rem;
  }

  .project-grid {
    grid-template-columns: 1fr;
    padding: 15px;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .projectImg {
    max-width: 100%;
  }
}

.projectButton {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 5px;
  border: 1px solid #00ff00;
  background: transparent;
  color: #00ff00;
  font-family: "Terminus", monospace;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.projectButton:hover {
  background: #00ff00;
  color: black;
}

.project-buttons {
  text-align: center;
  margin-top: 20px;
}

.terminalFooter {
  text-align: center;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.9);
  border-top: 1px solid #00ff00;
}

.carousel {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.carousel-item {
  flex: 1;
  margin: 0 10px;
  transition: transform 0.5s ease-in-out;
}

.carousel-button {
  background: transparent;
  border: 1px solid #00ff00;
  color: #00ff00;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.carousel-button:hover {
  background-color: #00ff00;
  color: black;
}

.carousel-progress-bar {
  height: 5px;
  background: rgba(255, 255, 255, 0.2);
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.carousel-progress {
  height: 100%;
  background: #00ff00;
  transition: width 0.05s ease-out;
}

.carousel-tabs {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.carousel-tab {
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.3);
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.carousel-tab.active {
  background: #00ff00;
}

.projects-terminal {
  background-color: rgba(0, 0, 0, 0.95);
  border: 1px solid #00ff00;
  border-radius: 5px;
  width: 85%;
  max-width: 1800px;
  /* increased from 1400px */
  margin: 20px auto;
  min-height: 80vh;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.2);
  overflow: hidden;
}

.terminal-header {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px;
  border-bottom: 1px solid #00ff00;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.terminal-title {
  color: #00ff00;
  font-family: "Courier New", monospace;
}

.terminal-controls {
  display: flex;
  gap: 8px;
}

.terminal-controls span {
  width: 12px;
  height: 12px;
  border: 1px solid #00ff00;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #00ff00;
  font-size: 10px;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 30px;
}

.project-card {
  background: #040a04cc;
  border: 1px solid #1a4a1a;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  align-items: center;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(0, 255, 0, 0.2);
  border-color: #00ff00;
}

/* Remove or comment out any conflicting image styles */
.project-card img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-bottom: 1px solid #00ff00;
  transition: all 0.3s ease;
}

/* Add new styles for app icons */
.project-card[data-type="mobile-app"] img {
  width: 140px;
  /* increased from 120px */
  height: 140px;
  /* increased from 120px */
  border-radius: 28px;
  /* increased from 24px */
  margin: 30px auto;
  /* increased from 20px */
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 12px rgba(0, 255, 0, 0.15);
  object-fit: contain;
}

/* Updated styles for desktop project images */
.project-card[data-id="tools"] img,
.project-card[data-id="unbolted"] img,
.project-card[data-id="meta"] img,
.project-card[data-id="raspi"] img,
.project-card[data-id="bike"] img {
  padding: 20px 40px 20px 20px;
  background: rgba(0, 0, 0, 0.8);
  width: 85%;
  height: 180px;
  display: block;
  margin: 20px auto;
  object-fit: contain;
}

/* Updated styles for LocalLens project images */
.project-card[data-id="locallens"] img {
  padding: 20px 40px 20px 20px;
  background: rgba(0, 0, 0, 0.8);
  width: 85%;
  height: 180px;
  display: block;
  margin: 20px auto;
  object-fit: contain;
}

.project-card[data-id="locallens"] iframe {
  width: 85%;
  height: 315px;
  display: block;
  margin: 20px auto;
}

/* Special handling for Cosmos project image */
.project-card[data-id="cosmos"] img {
  padding: 20px 40px 20px 20px;
  background: rgba(0, 0, 0, 0.8);
  width: 85%;
  height: 180px;
  display: block;
  margin: 20px auto;
  object-fit: contain;
  object-position: center;
}

.gallery-item.no-hover {
  pointer-events: none;
}

.gallery-item.no-hover iframe {
  pointer-events: auto;
}

.project-card:hover img {
  transform: scale(1.05);
  filter: brightness(1.1);
}

.project-info {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-info h3 {
  color: #00ff00;
  font-size: 1.4rem;
  margin-bottom: 15px;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(0, 255, 0, 0.3);
}

.project-info p {
  color: #cccccc;
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 1rem;
}

.see-more {
  display: inline-block;
  color: #00ff00;
  text-decoration: none;
  font-family: "Courier New", monospace;
  padding: 8px 16px;
  border: 1px solid #00ff00;
  border-radius: 4px;
  transition: all 0.3s ease;
  text-align: center;
  margin-top: auto;
  cursor: pointer;
}

.see-more:hover {
  background: #00ff00;
  color: black;
  text-decoration: none;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.4);
}

/* Tech tags for each project */
.project-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.project-tag {
  background: rgba(0, 255, 0, 0.1);
  color: #00ff00;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  border: 1px solid rgba(0, 255, 0, 0.3);
}

.project-status {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.status-dot {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.85rem;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.status-dot.live {
  background-color: #00ff00;
  color: #000;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
  cursor: pointer;
}

.status-dot.live:hover {
  background-color: #00cc00;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.5);
}

.status-dot.inactive {
  background-color: #666;
  color: #fff;
}

.status-dot.completed {
  background-color: #ffd700;
  color: #000;
}

.status-dot.hackathon {
  background-color: #ff00ff;
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8rem;
  margin-left: 5px;
}

.project-card a {
  cursor: pointer;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 255, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
  }
}

@media (max-width: 1200px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .project-grid {
    grid-template-columns: 1fr;
  }

  .projects-terminal {
    width: 92%;
  }

  .projects-section-title {
    font-size: 2rem;
  }

  /* Reduce spacing for images on mobile */
  .project-card img,
  .project-card[data-type="mobile-app"] img,
  .project-card[data-id="tools"] img,
  .project-card[data-id="unbolted"] img,
  .project-card[data-id="meta"] img,
  .project-card[data-id="raspi"] img,
  .project-card[data-id="bike"] img,
  .project-card[data-id="locallens"] img,
  .project-card[data-id="cosmos"] img,
  .project-card[data-id="block"] img {
    margin: 10px auto;
    padding: 10px;
    height: 150px;
  }

  /* Left align text on mobile */
  .project-info {
    text-align: left;
    padding: 15px;
  }

  .project-info h3 {
    font-size: 1.2rem;
  }

  .project-info p {
    font-size: 0.95rem;
  }

  /* Adjust tag display on mobile */
  .project-tags {
    justify-content: flex-start;
  }

  .see-all-container {
    margin: 20px 0;
  }
}

/* Enhanced styling for the filter buttons */
.project-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin: 15px 30px 25px 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 255, 0, 0.3);
}

.filter-label {
  color: #00ff00;
  font-family: "Courier New", monospace;
  margin-right: 15px;
  font-weight: bold;
  font-size: 1.05rem;
}

.filter-btn {
  background: rgba(0, 0, 0, 0.7);
  color: #00ff00;
  border: 1px solid #00ff00;
  padding: 8px 14px;
  font-family: "Courier New", monospace;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 255, 0, 0.1);
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
}

.filter-btn:hover {
  background: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 12px rgba(0, 255, 0, 0.3);
  transform: translateY(-2px);
}

.filter-btn:active {
  transform: translateY(1px);
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.2);
}

.filter-btn.active {
  background: #00ff00;
  color: #000;
  font-weight: bold;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.4);
}

.filter-link {
  color: #00ff00;
  text-decoration: none;
  cursor: pointer;
}

.filter-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .project-filters {
    justify-content: center;
    margin: 10px 10px 20px 10px;
    gap: 8px;
  }

  .filter-btn {
    font-size: 0.8rem;
    padding: 6px 10px;
    margin-bottom: 8px;
  }

  .filter-label {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}

.see-all-container {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.see-all-btn {
  background: rgba(0, 0, 0, 0.7);
  color: #00ff00;
  border: 1px solid #00ff00;
  padding: 8px 16px;
  font-family: "Courier New", monospace;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 255, 0, 0.1);
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
}

.see-all-btn:hover {
  background: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 12px rgba(0, 255, 0, 0.3);
  transform: translateY(-2px);
}

.see-all-btn:active {
  transform: translateY(1px);
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.2);
}

.project-card[data-id="block"] img {
  padding: 20px 40px 20px 20px;
  background: rgba(0, 0, 0, 0.8);
  width: 85%;
  height: 180px;
  display: block;
  margin: 20px auto;
  object-fit: contain;
}