.education-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.education-card {
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #00ff00;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.education-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.3);
}

.education-logo {
  flex: 0 0 120px;
  /* Increased from 80px to 120px */
  margin-right: 20px;
}

.education-logo img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: white;
  /* Add white background for the Purdue logo */
  padding: 10px;
  /* Add some padding to give the logo some breathing room */
}

.education-details {
  flex: 1;
}

.education-details h3 {
  color: #ffffff;
  margin: 0 0 10px 0;
  font-size: 1.5rem;
}

.education-details p {
  margin: 5px 0;
  color: #00ff00;
}

.education-details .degree {
  font-weight: 500;
  color: #ffffff;
}

.education-details .duration {
  font-style: italic;
  color: #cccccc;
  font-size: 0.9rem;
}

.education-details .activities {
  margin-top: 10px;
  line-height: 1.5;
}

.educationBox {
  background-color: rgba(0, 0, 0, 0.925);
  border: 1px solid #00ff00;
  border-radius: 5px;
  font-family: "Courier New", monospace;
  color: #00ff00;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 20px auto;
  overflow: hidden;
}

.educationHeader {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00ff00;
}

.terminalTitle {
  font-size: 14px;
  font-weight: bold;
}

.terminalControls {
  display: flex;
  gap: 5px;
}

.terminalControl {
  width: 12px;
  height: 12px;
  border: 1px solid #00ff00;
  border-radius: 50%;
  font-size: 10px;
  cursor: not-allowed;
}

.educationContent {
  padding: 15px;
  line-height: 1.6;
}

.education-item {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.education-info {
  flex: 1;
}

.education-info h3 {
  margin: 0;
  font-size: 1.4rem;
  color: #ffffff;
}

.education-degree {
  display: block;
  font-size: 1rem;
  color: #ffffff;
  margin-bottom: 5px;
}

.education-duration {
  display: block;
  font-style: italic;
  color: #cccccc;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.education-activities {
  margin: 10px 0;
  line-height: 1.5;
}

.education-divider {
  height: 1px;
  background-color: #00ff00;
  margin: 25px 0;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.educationFooter {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px;
  border-top: 1px solid #00ff00;
  text-align: center;
}

.contact-text {
  margin: 0;
  color: #00ff00;
}

.contact-link {
  color: #ffffff;
  text-decoration: underline;
}

/* Media Queries for Responsive Layout */
@media (max-width: 768px) {
  .education-card {
    flex-direction: column;
    padding: 15px;
  }

  .education-logo {
    margin-right: 0;
    margin-bottom: 15px;
    max-width: 100px;
    /* Center the logo container */
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    /* Add more space at the top */
  }

  .education-logo img {
    /* Center the image within the container */
    display: block;
    margin: 0 auto;
    max-width: 80%;
    margin-top: 10px;
    /* Move image down */
  }

  .education-details h3 {
    font-size: 1.2rem;
  }

  .education-item {
    flex-direction: column;
    align-items: flex-start;
    /* Left align instead of center */
    text-align: left;
    /* Left align text */
    padding: 0 10px;
    /* Add some padding */
  }

  .education-info {
    width: 100%;
    text-align: left;
    /* Left align all text in the info section */
    margin-top: 15px;
    /* Add space between logo and info */
  }

  .education-info h3 {
    font-size: 1.2rem;
    text-align: left;
    margin-bottom: 8px;
  }

  .education-degree {
    font-size: 0.9rem;
    text-align: left;
  }

  .education-duration {
    text-align: left;
    margin-bottom: 8px;
  }

  .education-activities {
    text-align: left;
    line-height: 1.4;
  }

  .educationContent {
    padding: 10px;
  }

  .education-divider {
    margin: 20px 0;
  }

  .educationFooter {
    padding: 8px;
    font-size: 0.9rem;
  }
}