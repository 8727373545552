.fullProjectContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: black;
  padding: 40px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.fullProjectDetails {
  position: relative;
}

.backButton {
  position: absolute;
  top: -30px;
  left: 0px;
  text-decoration: none;
  color: #00ff00;
  z-index: 2;
}

.backButton:hover {
  text-decoration: underline;
}

.projectDetailsText {
  color: #00ff00;
  line-height: 1.6;
}

.marqueeContainer {
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.marquee {
  display: flex;
  animation: marquee 45s linear infinite;
}

.marqueeImage {
  width: 400px;
  height: 300px;
  object-fit: cover;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.project-details-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.95);
  border: 1px solid #fafafa;
  border-radius: 8px;
  color: #00ff00;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.1);
}

.back-button {
  display: inline-block;
  color: #00ff00;
  text-decoration: none;
  margin-bottom: 2rem;
  font-family: "Courier New", monospace;
  transition: all 0.3s ease;
}

.back-button:hover {
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  text-decoration: underline;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.project-header h1 {
  color: #ffffff;
  font-size: 2rem;
  margin: 0;
  font-weight: normal;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.live-link {
  color: #00ff00;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid #00ff00;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.live-link:hover {
  background: #00ff00;
  color: black;
}

.carousel-wrapper {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 1rem 0;
  scroll-snap-type: x mandatory;
}

.carousel-card {
  flex: 0 0 auto;
  width: 300px;
  height: 200px;
  border: 1px solid #00ff00;
  border-radius: 4px;
  overflow: hidden;
  background: rgba(10, 25, 10, 0.8);
  scroll-snap-align: center;
  transition: transform 0.3s ease;
}

.carousel-card:hover {
  transform: scale(1.05);
}

.carousel-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app-gallery {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 20px 0;
  margin: 20px -20px;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;
  scrollbar-color: #00ff00 rgba(0, 255, 0, 0.1);
}

.app-gallery::-webkit-scrollbar {
  height: 8px;
}

.app-gallery::-webkit-scrollbar-track {
  background: rgba(0, 255, 0, 0.1);
  border-radius: 4px;
}

.app-gallery::-webkit-scrollbar-thumb {
  background: #00ff00;
  border-radius: 4px;
}

.gallery-item {
  flex: 0 0 auto;
  width: 600px;
  /* increased from 300px */
  height: 400px;
  /* decreased from 600px */
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ffffff;
  scroll-snap-align: start;
  transition: transform 0.3s ease;
  position: relative;
  /* Add this to ensure proper positioning of pseudo-elements */
}

.gallery-item.mobile-screenshot {
  width: 300px;
  height: 600px;
}

.gallery-item:hover {
  transform: scale(1.02);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .gallery-item {
    width: 300px;
    /* decreased from previous mobile size */
    height: 200px;
    /* decreased from previous mobile size */
  }

  .gallery-item.mobile-screenshot {
    width: 200px;
    height: 400px;
  }

  .app-gallery {
    gap: 15px;
  }

  /* Force the title to appear above links for project pages that have header-links */
  .project-header .header-links {
    order: 2;
  }

  /* Better project content spacing for mobile */
  .project-content {
    margin-top: 1.5rem;
  }

  .projectDetailsText {
    padding: 0 0.25rem;
  }

  /* Better handling of gallery images on mobile */
  .app-gallery {
    scrollbar-width: thin;
    position: relative;
    padding-bottom: 25px;
    /* Add space for the faded edges effect */
  }

  /* Add faded edges to indicate scrolling */
  .app-gallery::before,
  .app-gallery::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 35px;
    pointer-events: none;
    z-index: 2;
  }

  .app-gallery::before {
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.95), transparent);
  }

  .app-gallery::after {
    right: 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.95), transparent);
  }

  /* Add a scroll hint for gallery */
  .app-gallery {
    position: relative;
  }

  /* Remove the current swipe hint */
  .app-gallery::after {
    content: "← Swipe to see more →";
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.8rem;
    color: rgba(0, 255, 0, 0.7);
    pointer-events: none;
    animation: pulse-opacity 2s infinite;
  }

  /* Add a better swipe hint overlay */
  .app-gallery {
    position: relative;
  }

  .app-gallery::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent);
    z-index: 2;
    pointer-events: none;
  }

  .app-gallery::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent);
    z-index: 2;
    pointer-events: none;
  }

  /* Update swipe hint to work with the show-hint class */
  .app-gallery .gallery-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    /* Hide by default */
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    color: #00ff00;
    font-size: 1.2rem;
    font-weight: bold;
    z-index: 10;
    pointer-events: none;
    animation: pulse-opacity 2s infinite;
    backdrop-filter: blur(2px);
  }

  .app-gallery.show-hint .gallery-item:first-child::before {
    content: "← Swipe →";
    display: flex;
    /* Only show on the first item when show-hint is active */
  }

  /* Modify gradient indicators to be more prominent when hint is showing */
  .app-gallery.show-hint::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent);
    z-index: 2;
    pointer-events: none;
  }

  .app-gallery.show-hint::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent);
    z-index: 2;
    pointer-events: none;
  }

  @keyframes pulse-opacity {
    0% {
      opacity: 0.4;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.4;
    }
  }
}

@media (max-width: 1200px) {
  .fullProjectContainer {
    padding: 10px;
  }

  .fullProjectDetails {
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 30px;
  }

  .backButton {
    top: 10px;
    font-size: 14px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .projectImage {
    max-height: 300px;
  }

  .marqueeImage {
    width: 150px;
    height: 150px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }
}

.image-carousel,
.carousel-content,
.carousel-btn,
.carousel-indicators {
  display: none;
}

.app-title-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.app-icon {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: contain;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.15);
}

.app-title-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.app-title-content h1 {
  margin: 0;
  font-size: 2rem;
  color: #ffffff;
}

/* Remove the app-meta styles since we're not using them anymore */
.app-meta,
.app-divider {
  display: none;
}

@media (max-width: 768px) {
  .project-details-container {
    padding: 1.5rem;
    margin: 20px auto;
  }

  .project-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }

  .app-title-section {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
    gap: 0.75rem;
  }

  .app-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 0.5rem;
  }

  .app-title-content h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }

  .header-links {
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 1rem;
  }

  .live-link {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .back-button {
    margin-bottom: 1.25rem;
    font-size: 0.9rem;
  }

  .gallery-item {
    width: 280px;
    /* Further reduced width */
    height: 180px;
    /* Further reduced height */
    margin-right: 10px;
    /* Add some spacing between items */
  }

  .gallery-item.mobile-screenshot {
    width: 180px;
    height: 360px;
  }

  .app-gallery {
    padding: 15px 0;
    margin: 10px -15px;
    gap: 10px;
  }

  .project-section {
    margin-bottom: 1.5rem;
  }

  .project-section h2 {
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
  }

  .project-section p,
  .project-section li {
    font-size: 0.95rem;
    line-height: 1.4;
  }

  /* Force the title to appear above links for project pages that have header-links */
  .project-header .header-links {
    order: 2;
  }
}

/* Add padding and zoom adjustments for specific projects */
[data-project="tools"] .gallery-item,
[data-project="unbolted"] .gallery-item {
  padding: 5px;
  background: rgba(0, 0, 0, 0.8);
}

[data-project="meta"] .gallery-item img {
  object-fit: contain;
  transform: scale(0.9);
  background: rgba(0, 0, 0, 0.8);
}

.header-links {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.github-link {
  background: rgba(0, 0, 0, 0.3);
}

.project-section {
  margin-bottom: 2.5rem;
}

.project-section h2 {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 255, 0, 0.3);
  padding-bottom: 0.5rem;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.project-section p {
  margin-bottom: 1rem;
}

.project-section ul {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.video-container {
  margin: 20px auto;
  max-width: 100%;
}

.video-container iframe {
  width: 100%;
  height: auto;
  display: block;
  aspect-ratio: 16/9;
}

.app-gallery img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.app-gallery .gallery-item {
  flex: 0 0 auto;
  width: 600px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #00ff00;
  scroll-snap-align: start;
  transition: transform 0.3s ease;
  background: rgba(0, 0, 0, 0.8);
}

.app-gallery .gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20px;
}

.project-content {
  margin-top: 2rem;
}